import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

import SolutionsImage from "../components/solutions/solutionsImage"
import SolutionsModule from "../components/solutions/solutionsModule"
import SolutionsimgtitleModule1 from "../components/solutions/solutionsimgtitleModule1"
import SolutionsfeatureBlueModule from "../components/solutions/solutionsfeatureBlueModule"
import FeatureModule from "../components/common/featureModule"
import SolutionsimgtitleModule2 from "../components/solutions/solutionsimgtitleModule2"

// import ParticiimgModule from "../components/participants/particiimgModule"
import CTAModule from "../components/common/ctaModule"
import { FeatureModel } from "../components/investors/model"

const SolutionsPage = () => (
  <Layout>
        <SEO title="Solutions" />
        <Container className="pad_header">
            <h1 className="header_title">Solutions</h1>
        </Container>
        <SolutionsImage />
        <SolutionsModule />
        <SolutionsimgtitleModule1 />
        <SolutionsfeatureBlueModule />
        <FeatureModule model={FeatureModel}/>
        <SolutionsimgtitleModule2 />
        <CTAModule 
            title="Connect with a Jus-Pharma team member today!"
            css='background_f'
        />
  </Layout>
)

export default SolutionsPage

