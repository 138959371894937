import React from "react"
import { Container, CardDeck, Card } from 'react-bootstrap'
import { CardModel } from "../investors/model"

const SolutionsfeatureBlueModule = pros => {
    return (
        <div className="section_pad_st1">
            <Container>
                <CardDeck className="background_mask_blue margin_0 pad_65">
                    {CardModel.map((item, i) => {
                        return (
                            <Card className="text-center featured_card" key={i}>
                                <Card.Img className="w-75 mx-auto max_w_140" variant="top" src={item.icon} alt={item.subtitle}/>
                                <Card.Body>
                                    <Card.Title className="sub_title main_w_color">{item.subtitle}</Card.Title>
                                    <Card.Text className="main_text main_w_color">{item.subtext}</Card.Text>
                                </Card.Body>
                            </Card>
                          )
                    })}
                </CardDeck>
            </Container>
        </div>
    )
}

export default SolutionsfeatureBlueModule

