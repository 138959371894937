import React from "react"
import { Container, Row, Col, Image } from 'react-bootstrap'
import { SolutionsimgtitleModel1 } from "./model"

const SolutionsimgtitleModule1 = () => {
    return (
      <div className="section_pad_st1">
        <Container>
            <Row className="margin_0">
              <Col
                className="col-sm-6 pad_0"
              >
                <Image src={SolutionsimgtitleModel1.image} alt="solution-suc" className="w-100"/>
              </Col>
              <Col
                className="col-sm-6 pad_lr_50"
              >
                <h4 className="main_title main_b_color">{SolutionsimgtitleModel1.title}</h4>
                <p className="main_text main_b_color">{SolutionsimgtitleModel1.text1}</p>
                <p className="main_text main_b_color">{SolutionsimgtitleModel1.text2}</p>
              </Col>
            </Row>
        </Container>
        </div>
    )
  }

export default SolutionsimgtitleModule1