import React from 'react'
import { Image } from 'react-bootstrap'
import { SolutionsHeroModel } from "./model"

const SolutionsHeroBanner = () => {
  return (
    <Image src={SolutionsHeroModel.image} alt="Solutions-hero" className="generalHerobanner"/>
  )
}

export default SolutionsHeroBanner