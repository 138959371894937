import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { SolutionsModel } from "./model"

const SolutionsModule = () => {
    return (
        <Container className="margin_bot_80">
        <div className="mt-340">
            <Row className="margin_0">
                {SolutionsModel.map((item, i) => {
                    return (
                        <Col key={i}
                            className={`background_mask_blue col-sm-6 pad_65 ${item.class}`}
                        >
                            <div className="align-self-center">
                                <h4 className="main_title main_w_color">{item.title}</h4>
                                <hr />
                                <p className="main_text main_w_color">{item.text}</p>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </div>
        
        </Container>
    )
}

export default SolutionsModule