
import hero from "../../images/solutions-hero.jpg"
import image1 from "../../images/solu-1.jpg"
import image2 from "../../images/solu-2.jpg"

export const SolutionsHeroModel = {
    image: hero,
}

export const SolutionsModel = [
    {
        title: "For Pharmaceutical Companies",
        text: "Our team assists pharmaceutical companies in evaluating data and verifying documents, making resource decisions, coordinating study site communications, managing regulatory compliance and identifying opportunities.  We also help pharmaceutical companies connect prospective trial participants with the ideal studies that address their unique challenges.",
        class: "",
    },
    {
        title: "For Contract Research Organizations",
        text: "Imagine improving, accuracy, safety and speed to market for client sponsors that outsource the clinical investigation process. CRO companies who work with us, not only exponentially increase demand for their services, but increase their bandwidth to accommodate that uptick in business. Achieving shorter study times overall means more CRO clients can be accommodated at once, without incurring substantive infrastructure costs.",
        class: "border_l_f",
    },
]

export const SolutionsimgtitleModel1 = {
    image: image1,
    title: "Solutions",
    text1: "Jus-Pharma is technology, guidance and service delivered to organizations conducting clinical studies. In addition to the technical platform, we deliver comprehensive clinical, business, legal and technical experience to buttress investigatory excellence in managing the entire landscape and facilitate clear communication amongst all parties at every stage of research. Furthermore, we connect pharmaceutical companies with prospective trial participants ideal for studies that address their unique challenges.",
}

export const SolutionsimgtitleModel2 = {
    image: image2,
    title: "State of the art",
    text: "Jus-Pharma proves state-of-the-art at every level - implementing blockchain features and auditing all digital transactions on the platform. Our team elucidates real-time clinical trial intelligence, ensuring transparency and confidence while reducing the time needed to bring a new drug or therapeutic advancement to market.",
}