import React from "react"
import { Container, Row, Col, Image } from 'react-bootstrap'
import { SolutionsimgtitleModel2 } from "./model"

const SolutionsimgtitleModule2 = () => {
    return (
        <div className="callout_background">
            <Container>
                <Row className="margin_0">
                    <Col
                        className="col-sm-6 pad_lr_50 align-self-center"
                    >
                        <h4 className="main_title main_b_color">{SolutionsimgtitleModel2.title}</h4>
                        <p className="main_text main_b_color">{SolutionsimgtitleModel2.text}</p>
                    </Col>
                    <Col
                        className="col-sm-6 pad_0"
                    >
                        <Image src={SolutionsimgtitleModel2.image} alt="solution-check" className="w-100"/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SolutionsimgtitleModule2